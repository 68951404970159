import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/cp.scss';
import 'bootstrap';
import { store, key } from './store';
import { DataConfigService } from '@cito/cp-data';
import VueTelInput from 'vue3-tel-input';
import { sentry } from './utils';

const app = createApp(App).use(store, key).use(VueTelInput).use(router);

// Setup authentication and API Base path for the cp-data library
const authFunc = async () => {
  if (store.state.auth.loading) {
    await store.state.auth.loadingPromise();
  }

  return store.state.auth.token;
};

const handleErrorFunc = async (err: any) => {
  if (err.response) {
    if (err.response.status === 401) {
      store.state.auth.saveRoute(router.currentRoute.value.fullPath);
      store.state.auth.logout();
      router.push({ path: '/' });
    } else if (err.response.status === 500) {
      return err.response;
    } else if (err.response.status === 422) {
      return err.response;
    }
  }
  return err;
};

new DataConfigService(authFunc, handleErrorFunc, `${store.state.apiBase}/admin`);

const useSentry = process.env.VUE_APP_SENTRY_ACTIVE === 'true';
sentry.init(useSentry, app, router);

app.mount('#app');
