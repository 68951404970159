import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import { GetDocTemplateDataResponse } from '@cito/cp-data';

export class DocTemplateDealService extends BaseService {
  public async getDocTemplateData(
    templateId: string,
    dealId: string
  ): Promise<GetDocTemplateDataResponse> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/doctemplates/${templateId}/data/${dealId}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    return res.data as GetDocTemplateDataResponse;
  }

  public async getDocTemplatePDF(templateId: string, dealId: string): Promise<any> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${store.state.apiBase}/admin/doctemplates/${templateId}/pdf/${dealId}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    return res.data;
  }
}
