<template>
  <div class="header-global d-flex justify-content-end align-items-center px-2">
    <div class="me-4">
      <div v-if="isLoggedIn"><span class="logout-button" @click="logout()">Logout</span></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();

    const logout = () => {
      store.state.auth.logout();
      router.replace({ path: '/login' });
    };

    const isLoggedIn = computed(() => {
      return store.state.auth.isAuthenticated;
    });

    return {
      isLoggedIn,
      logout
    };
  }
});
</script>
<style lang="scss">
.header-global {
  border-bottom: 1px solid lightgray;
  background-color: #dcdcdc;
  height: 7vh;
}

.logout-button {
  cursor: pointer;
}
</style>
