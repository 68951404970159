import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import {
  AdminUserToken,
  AdminLoginRequest,
  AdminLoginResponse,
  AdminResetPasswordRequest
} from '@cito/cp-data';

export class AuthService extends BaseService {
  public async login(user: string, password: string): Promise<AdminUserToken | null> {
    const req: AdminLoginRequest = {
      data: {
        user: user,
        password: password
      }
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/auth/admin/login`,
      data: req
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);

      const response: AdminLoginResponse = res.data as AdminLoginResponse;

      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          return null;
        }
      }
      throw err;
    }
  }

  public async sendResetPasswordEmail(email: string): Promise<boolean> {
    const req: AdminResetPasswordRequest = {
      data: {
        email: email
      }
    };
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'put',
      url: `${store.state.apiBase}/auth/admin/password/reset`,
      data: req
    };

    try {
      await Axios.default(apiConfig);
      return true;
    } catch (_) {
      // Do nothing
    }

    return false;
  }

  public async resetPassword(resetId: string, newPassword: string): Promise<boolean> {
    const req: AdminResetPasswordRequest = {
      data: {
        resetId: resetId,
        newPassword: newPassword
      }
    };
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'put',
      url: `${store.state.apiBase}/auth/admin/password/reset`,
      data: req
    };

    try {
      await Axios.default(apiConfig);
      return true;
    } catch (_) {
      // Do nothing
    }

    return false;
  }

  public async validate(token: string): Promise<any | null> {
    // Define strong type
    const req = {
      data: {
        token: token
      }
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/auth/admin/token`,
      data: req
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);

      const response = res.data;
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          return null;
        }
      }
      throw err;
    }
  }
}
