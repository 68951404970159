import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header-global d-flex justify-content-end align-items-center px-2" }
const _hoisted_2 = { class: "me-4" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "logout-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
            }, "Logout")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}