import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';

export class AsicLookupService extends BaseService {
  public async doLookup(
    abn: string,
    acn?: string,
    isAdmin?: boolean
  ): Promise<Axios.AxiosResponse> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/data/asic`,
      params: { abn, acn, isAdmin }
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    return res as Axios.AxiosResponse;
  }
}
