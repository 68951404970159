import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Vue } from '@sentry/vue/types/types';

export const sentry = {
  init: (useSentry: boolean, app: Vue, router: any) => {
    const env = process.env.VUE_APP_ENV || 'dev';
    const dsn = process.env.VUE_APP_SENTRY_DSN;

    if (!useSentry || !dsn) {
      return;
    }

    Sentry.init({
      app,
      dsn,
      environment: env,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [
            'localhost',
            'admin.test.citoplus.com.au',
            'admin.demo.citoplus.com.au',
            'admin.citoplus.com.au',
            /^\//
          ]
        }),
        new Sentry.Replay()
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 0.1
    });
  },
  addSessionIdTag: (useSentry: boolean, sessionId: string) => {
    const dsn = process.env.VUE_APP_SENTRY_DSN;
    if (!useSentry || !dsn) {
      return;
    }
    Sentry.setTag('cito_session_id', sessionId);
  }
};
