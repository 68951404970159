import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { Auth } from '@/domain/security';

// Cito+ specific state typings
export interface State {
  apiBase: string;
  requestUrlBase: string;
  auth: Auth;
  isLoggedIn: boolean;
  organisation: OrganisationEntity;
  user: UserEntity;
}

// Define type injection key
export const key: InjectionKey<Store<State>> = Symbol();

// Define our own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key);
}

// Environment configurarion
import env_config from '../../env_config.json';
import { OrganisationEntity, UserEntity } from '@cito/cp-data';

const env = process.env.VUE_APP_ENV || 'dev';

console.log(`environment: ${env}`);

// Create the store
export const store = createStore<State>({
  state: {
    apiBase: (env_config.envs as any)[env].apiBase,
    requestUrlBase: (env_config.envs as any)[env].requestUrlBase,
    auth: new Auth(),
    isLoggedIn: false,
    organisation: new OrganisationEntity(),
    user: new UserEntity()
  },
  mutations: {},
  actions: {},
  modules: {}
});

store.state.auth.loadFromStore();
