import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import { SharedAccessGroup, SharedAccessGroupAdmin } from '@cito/cp-data';

export class AccessGroupService extends BaseService {
  public async getAccessGroups(): Promise<SharedAccessGroupAdmin[]> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/accessGroups`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);
    const response = res.data;
    return response.data ? response.data : [];
  }

  public async getAccessGroup(id: string): Promise<SharedAccessGroup> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/accessGroups/${id}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);
    const response = res.data;
    return response.data;
  }

  public async saveAccessGroup(accessGroup: SharedAccessGroup): Promise<string> {
    if (accessGroup.id && accessGroup.id !== '') {
      return await this.updateAccessGroup(accessGroup.id, accessGroup);
    } else {
      return await this.createAccessGroup(accessGroup);
    }
  }

  public async updateAccessGroup(id: string, accessGroup: SharedAccessGroup): Promise<string> {
    const req = {
      data: accessGroup
    };
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'put',
      url: `${store.state.apiBase}/admin/accessGroups/${id}`,
      data: req
    };

    await this.makeSecureCall(apiConfig);
    return accessGroup.id;
  }

  public async createAccessGroup(accessGroup: SharedAccessGroup): Promise<string> {
    const req = {
      data: accessGroup
    };
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/admin/accessGroups`,
      data: req
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);
    const response = res.data;
    return response.data.id;
  }

  public async deleteAccessGroup(id: string): Promise<void> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'delete',
      url: `${store.state.apiBase}/admin/accessGroups/${id}`
    };

    await this.makeSecureCall(apiConfig);
  }
}
