import * as Axios from 'axios';
import { store } from '@/store';
import { router } from '@/router';

export default class BaseService {
  protected async makeSecureCall(config: Axios.AxiosRequestConfig): Promise<Axios.AxiosResponse> {
    if (store.state.auth.loading) {
      await store.state.auth.loadingPromise();
    }

    try {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = store.state.auth.token;
      return await Axios.default(config);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          store.state.auth.saveRoute(router.currentRoute.value.fullPath);
          store.state.auth.logout();
          router.push({ path: '/login' });
        } else if (err.response.status === 500) {
          return err.response;
        } else if (err.response.status === 422) {
          return err.response;
        }
      }
      throw err;
    }
  }
}
