import * as Axios from 'axios';
import BaseService from './BaseService';
import { store } from '@/store';

export class OrganisationService extends BaseService {
  private static instance: OrganisationService;

  constructor() {
    super();
    if (OrganisationService.instance) {
      return OrganisationService.instance;
    }

    OrganisationService.instance = this;
  }

  public async getDealCountForOrganisation(organisationId: string): Promise<any | undefined> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/organisations/deals/${organisationId}`
    };
    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data?.data) {
      return res.data.data;
    }
    return undefined;
  }
}
