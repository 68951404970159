<template>
  <div class="d-flex">
    <div v-if="isLoggedIn" id="main-side-nav">
      <div class="row mt-2 mb-5">
        <div class="col d-flex justify-content-center">
          <router-link to="/">
            <img src="/images/logo.png" class="main-side-nav-img" />
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/organisation/">
            <i class="bi bi-bank" aria-label="Broker Group Management"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/aggregator/">
            <i class="bi bi-intersect" aria-label="Aggregator Management"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/teams">
            <i class="bi bi-people" aria-label="Teams Management"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/lenders">
            <i class="bi bi-building" aria-label="Lender Management"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/users/">
            <i class="bi bi-person" aria-label="User Management"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/onboarding/">
            <i class="bi bi-file-earmark-arrow-up" aria-label="Upload Borrower"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/reports/">
            <i class="bi bi-graph-up-arrow" aria-label="Reporting"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/portal/">
            <i class="bi bi-gear" aria-label="Portal Settings"></i>
          </router-link>
        </div>
      </div>
      <div class="row my-4">
        <div class="col d-flex justify-content-center main-side-nav-icon">
          <router-link to="/doctemplates/">
            <i class="bi bi-file-text" aria-label="Document Templates"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div id="main-app-content" :class="{ 'main-full-width': !isLoggedIn }">
      <router-view name="header"></router-view>
      <router-view :key="$route.path" class="nav-space fill-height pb-4" />
      <router-view name="footer"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    const isLoggedIn = computed(() => {
      return store.state.auth.isAuthenticated;
    });

    watch(
      () => route.path,
      () => {
        if (
          route.path === '/organisation/create' ||
          route.path === `/organisation/update/${route.params.organisationId}` ||
          route.path === '/users/create' ||
          route.path === `/users/update/${route.params.userId}`
        ) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.removeProperty('overflow');
        }
      }
    );

    return {
      isLoggedIn
    };
  }
});
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fill-height {
  flex-grow: 1;
  width: 100%;
}

#main-side-nav {
  width: 5%;
  background: #d3d3d3;
  min-height: 100vh;
}

.main-side-nav-img {
  width: 70px;
}

.main-side-nav-icon {
  color: white;
  font-size: 20px;
}

#main-app-content {
  width: 95%;
}

.main-full-width {
  width: 100% !important;
}

.main-loading {
  height: 100vh;
}
</style>
