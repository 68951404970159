import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import AppHeader from '@/components/navigation/AppHeader.vue';
import AppFooter from '@/components/navigation/AppFooter.vue';
import { store } from '@/store';

const guardSecure = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (store.state.auth.loading) {
    await store.state.auth.loadingPromise();
  }
  if (store.state.auth.isAuthenticated) {
    next();
  } else {
    store.state.auth.saveRoute(to.fullPath);
    next('/login');
  }
};

const guardLogout = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (store.state.auth.loading) {
    await store.state.auth.loadingPromise();
  }
  store.state.auth.logout();
  next();
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Home.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: guardLogout,
    components: {
      header: AppHeader,
      default: () => import('../views/Login.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/organisation/',
    name: 'OrganisationHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Organisations.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/organisation/:tabName/:organisationId?',
    name: 'Organisation',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Organisations.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/aggregator/',
    name: 'AggregatorHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Aggregators.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/aggregator/:tabName/:aggregatorId?',
    name: 'Aggregator',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Aggregators.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/teams/',
    name: 'TeamHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Teams.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/teams/:tabName/:teamId?',
    name: 'Teams',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Teams.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/lenders/',
    name: 'LendersHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Lenders.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/lenders/:tabName/:lenderId?',
    name: 'Lenders',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Lenders.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/users/',
    name: 'UsersHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Users.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/users/:tabName/:userId?',
    name: 'Users',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Users.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/onboarding/',
    name: 'OnBoardingHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/OnBoarding.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/onboarding/:tabName',
    name: 'OnBoarding',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/OnBoarding.vue'),
      footer: AppFooter
    }
  },

  {
    path: '/resetpassword',
    name: 'ResetPassword',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/ResetPassword.vue'),
      footer: AppFooter
    },
    meta: {
      hideMenu: true
    }
  },

  {
    path: '/reports/',
    name: 'ReportsHome',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Reports.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/reports/:tabName',
    name: 'Reports',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Reports.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/portal/',
    name: 'PortalSettings',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Portal.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/portal/:tabName/:bannerId?',
    name: 'PortalView',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/Portal.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/doctemplates/',
    name: 'DocumentTemplates',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/DocTemplates.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/doctemplates/:tabName/:templateId?',
    name: 'DocumentTemplatesView',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/DocTemplates.vue'),
      footer: AppFooter
    }
  },

  {
    path: '/report/download/:reportID?',
    name: 'GenerateReport',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import('../views/DownloadReport.vue'),
      footer: AppFooter
    }
  }
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
