<template>
  <div class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="navbar-brand mx-auto d-flex align-items-center justify-content-center">
            <span class="footer-text">Powered by</span>
            <img class="ms-2 mb-1" src="/images/logo.png" height="15" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid lightgray;
  font-size: 16px;
  background-color: #dcdcdc;
}

.footer-text {
  font-size: 16px;
}
</style>
