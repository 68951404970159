import { User } from '@cito/cp-data';
import { AuthService } from '@/services';
import { sentry } from '../../utils';

export class Auth {
  private _isAuthenticated = false;
  private _user: User | null = null;
  private _token = '';
  private _loading = false;
  private _tokenAccessed = false;
  private _savedRoute: string | undefined;
  private svc = new AuthService();
  private useSentry = process.env.VUE_APP_SENTRY_ACTIVE === 'true';

  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public get user(): User | null {
    return this._user;
  }

  public get token(): string {
    this._tokenAccessed = true;
    return this._token;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get savedRoute(): string | undefined {
    return this._savedRoute;
  }

  public async login(user: string, password: string): Promise<void> {
    this._loading = true;

    const ret: any = await this.svc.login(user, password); // DEFINE STRONG TYPE
    if (ret && ret.user && ret.token) {
      this._isAuthenticated = true;
      this._user = ret.user;
      this._token = ret.token;
      if (ret.sessionId) {
        sentry.addSessionIdTag(this.useSentry, ret.sessionId);
      }

      this.startTokenRefreshPoll();
    } else {
      this.clear();
    }

    this.saveToStore();

    this._loading = false;
  }

  public logout(): void {
    this.clear();
    this.saveToStore();
  }

  private clear(): void {
    this._isAuthenticated = false;
    this._user = null;
    this._token = '';
  }

  public async loadFromStore(): Promise<void> {
    const token = localStorage.getItem('token');
    this._token = token ? token : '';

    await this.validateStore();

    this.startTokenRefreshPoll();
  }

  public async sendResetPasswordEmail(email: string): Promise<boolean> {
    this._loading = true;
    const result = await this.svc.sendResetPasswordEmail(email);
    this._loading = false;

    return result;
  }

  public async resetPassword(resetId: string, newPassword: string): Promise<boolean> {
    this._loading = true;
    const result = await this.svc.resetPassword(resetId, newPassword);
    this._loading = false;

    return result;
  }

  private saveToStore(): void {
    this.saveTokenToStore();
  }

  private saveTokenToStore(): void {
    localStorage.setItem('token', this._token);
  }

  private async validateStore(): Promise<void> {
    this._loading = true;
    const ret = this._token ? await this.svc.validate(this._token) : undefined;
    if (ret) {
      this._isAuthenticated = true;
      this._user = ret.user;
      if (ret.sessionId) {
        sentry.addSessionIdTag(this.useSentry, ret.sessionId);
      }
      if (ret.token) {
        this._token = ret.token;
        this.saveTokenToStore();
      }
    } else {
      this.clear();
    }
    this._loading = false;
  }

  public async loadingPromise(): Promise<void> {
    while (this.loading) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    return;
  }

  private startTokenRefreshPoll() {
    this._tokenAccessed = false;
    setTimeout(
      () => {
        if (this._tokenAccessed) {
          this.loadFromStore();
        }
      },
      14 * 60 * 1000
    ); // 14 minutes
  }

  public saveRoute(route: string): void {
    this._savedRoute = route;
  }

  public clearRoute(): void {
    this._savedRoute = undefined;
  }
}
