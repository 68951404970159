import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import { GoogleAddressSuggestion } from '@cito/cp-data';

export class PropertyLookupService extends BaseService {
  public async getSuggestions(query: string): Promise<{ placeId: string; description: string }[]> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/data/google/suggestions`,
      params: {
        query: query
      }
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data?.data) {
      return res.data.data as GoogleAddressSuggestion[];
    }
    return [];
  }

  public async getGoogleAddress(query: string): Promise<any> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/data/google/placeInfo/${query}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data.data) {
      return res.data.data;
    }
    return;
  }
}
