import * as Axios from 'axios';
import BaseService from './BaseService';
import { store } from '@/store';
import { AdminReportType, GenerateAdminReportRequest } from '@cito/cp-data';

export class ReportService extends BaseService {
  private static instance: ReportService;

  constructor() {
    super();
    if (ReportService.instance) {
      return ReportService.instance;
    }

    ReportService.instance = this;
  }

  public async getReport(
    reportType: AdminReportType,
    startDate: string,
    endDate: string,
    adminId: string,
    duration?: string,
    brokerGorup?: string | undefined
  ): Promise<any | undefined> {
    const req: GenerateAdminReportRequest = {
      data: {
        reportType: reportType,
        adminId: adminId,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          brokerGroup: brokerGorup,
          period: duration
        }
      }
    };
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/admin/reports`,
      data: req
    };
    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data?.data) {
      return res.data.data;
    }
    return undefined;
  }

  public async downloadReport(reportID: string): Promise<any | undefined> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/admin/reports/${reportID}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data) {
      return res;
    }
  }
}
